<template>
  <!-- <router-view /> -->
  <router-view />
</template>
<script>
import { ref, provide } from "vue";
import { useRouter } from "vue-router";
import "@/assets/css/public.css";
import "@/assets/css/el-style.less";

// 引入阿里图标
import "@/assets/font/iconfont.css";
import "@/assets/fontSize/font.css";

export default {
  setup() {},
};
</script>

<style lang="less">
/* 添加这段样式后，Primary Button 会变成红色 */
body {
  margin: 0;
  padding: 0;
  font-family: "montserrat";
  background-color: rgba(255, 255, 255, 0.774);
}
</style>
