const AddToData = {
  state: () => ({
    Data: {
      navShow: true,
    },
  }),
  mutations: {
    SET_AddToData: (state: any, data: any) => {
      state.Data = data;
    },
  },
  getters: {},
  actions: {},
};

export default AddToData;
