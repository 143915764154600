import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  createWebHashHistory,
} from "vue-router";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "main",
    redirect: "/login",
    component: () => import("@/views/mainPage.vue"),
    children: [
      // 登录页
      {
        path: "/login",
        name: "login",
        meta: {
          title: "登录",
          // 是否显示返回按钮
          IsButton: false,
        },
        component: () => import("@/views/login/index.vue"),
      },
      {
        path: "/AutoMonitor",
        name: "AutoMonitor",
        meta: {
          title: "自动化监控",
        },
        component: () => import("@/views/AutoMonitor/index.vue"),
      },
      {
        path: "/ProductionManagement",
        name: "ProductionManagement",
        meta: {
          title: "生产模式",
        },
        component: () => import("@/views/ProductionManagement/index.vue"),
      },
      {
        path: "/Craft",
        name: "Craft",
        meta: {
          title: "工艺追溯",
        },
        component: () => import("@/views/Craft/index.vue"),
      },
      {
        path: "/Personnel",
        name: "Personnel",
        meta: {
          title: "人员管理",
        },
        redirect: "/Personnel/Account",
        component: () => import("@/views/Personnel/index.vue"),
        children: [
          {
            path: "Account",
            name: "Account",
            component: () => import("@/views/Personnel/components/Account.vue"),
            meta: {
              title: "账号管理",
            },
          },
          {
            path: "PeopleManagement",
            name: "PeopleManagement",
            component: () =>
              import("@/views/Personnel/components/PeopleManagement.vue"),
            meta: {
              title: "人员管理",
            },
          },
          {
            path: "TeamOrGroup",
            name: "TeamOrGroup",
            component: () =>
              import("@/views/Personnel/components/TeamOrGroup.vue"),
            meta: {
              title: "班组管理",
            },
          },
          {
            path: "Tour",
            name: "Tour",
            component: () => import("@/views/Personnel/components/Tour.vue"),
            meta: {
              title: "交班记录",
            },
          },
        ],
      },
      {
        path: "/Operate",
        name: "Operate",
        meta: {
          title: "人员管理",
        },
        component: () => import("@/views/Operate/index.vue"),
      },

      {
        path: "/home",
        name: "home",
        meta: {
          title: "首页",
          // 是否显示返回按钮
          IsButton: false,
        },
        component: () => import("@/views/Home/home.vue"),
      },
    ],
  },
];

const router = createRouter({
  // history:  createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
});
import store from "@/store/index";
router.beforeEach((to: any, from, next) => {
  if (to?.query?.transferData) {
    let transferData = JSON.parse(to?.query?.transferData);
    store.commit("SET_TOKEN", transferData?.Token);
    store.commit("SET_companyId", transferData?.companyId);
    store.commit("GetNav", 1);
    store.commit("SET_projectId", transferData?.projectId);

    store.commit("SET_popoverShow", true);
    if (to?.query?.addToData) {
      let AddToData = JSON.parse(to?.query?.addToData);
      store.commit("SET_AddToData", AddToData);
    }

    next();
  } else if (to.path == "/login") {
    next();
  } else {
    if (store.state.token) {
      console.log(store.state.token);
      next();
    } else {
      next("/login");
    }
  }
});

export default router;
