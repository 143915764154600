import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// 引入element
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

import locale from "element-plus/lib/locale/lang/zh-cn";

// 引入全局的接口
import request from "@/assets/api/api";

// import "./utils/rem";
import "./utils/flexble.js";

import vuePopoverSelect from "vue-popover-select";
import "vue-popover-select/vue-popover-select.css";

/* 全局注册 */
let app = createApp(App);
import "@/assets/svgf";

app.config.globalProperties.$request = request;

// createApp(App).use(store).use(router).use(vant).mount("#app");
app.use(store);
app.use(router);
app.use(vuePopoverSelect);
app.use(ElementPlus, { locale });
app.mount("#app");
