const production = <any>{
  init: (fname: any, param: any) => production[fname](param),
  //工件类型
  workpieceList: (param: any) => ({
    method: "/saas/website-space/system-produce/select/item", // 接口地址
    data: param, // 请求参数
  }),
  // 获取工件工作模式详情
  JobMode: (param: any) => ({
    method: "/saas/website-space/system-produce/model/detail", // 接口地址
    data: param, // 请求参数
  }),
  // 新增工件
  CreateItem: (param: any) => ({
    method: "/saas/website-space/system-produce/create/item", // 接口地址
    data: param, // 请求参数
  }),
  // 工件工作模式按钮
  ModelButton: (param: any) => ({
    method: "/saas/website-space/system-produce/model/button", // 接口地址
    data: param, // 请求参数
  }),
  // 下拉生产模式选项
  ModelUsable: (param: any) => ({
    method: "/saas/website-space/system-produce/select/model/usable", // 接口地址
    data: param, // 请求参数
  }),
  // 新增工件工作模式名
  ModelNew: (param: any) => ({
    method: "/saas/website-space/system-produce/model/new", // 接口地址
    data: param, // 请求参数
  }),
  // 切换工件和工作模式
  CutPattern: (param: any) => ({
    method: "/saas/website-space/system-produce/set/model", // 接口地址
    data: param, // 请求参数
  }),
  // 保存工件工作模式
  ModelUpdate: (param: any) => ({
    method: "/saas/website-space/system-produce/model/update", // 接口地址
    data: param, // 请求参数
  }),
  // 编辑工件
  EditArtifact: (param: any) => ({
    method: "/saas/website-space/system-produce/modify/item", // 接口地址
    data: param, // 请求参数
  }),
  //  编辑模式
  EditMode: (param: any) => ({
    method: "/saas/website-space/system-produce/model/modify/name", // 接口地址
    data: param, // 请求参数
  }),
};
export default production;
